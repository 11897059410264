import style from "../styles/footer.module.css";
import RFmandal from "@public/assets/icons/RFmandal.svg";
import LFmandal from "@public/assets/icons/LFmandal.svg";

import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import Image from "next/legacy/image";
import { appDownloadUrl } from "@utils/BASE-URL";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { Box, Divider, Stack, Typography } from "@mui/material";
import DigitalIndia from "../../../public/assets/footer/digitalIndiaLogo.png";
import GooglePlayWebp from "../../../public/assets/footer/google_play.webp";
import theme from "../styles/theme";

const Footer = () => {
  const { t } = useTranslation();
  const router = useRouter();
  // const smallScreen = useMediaQuery("(max-width:750px)");
  // const [helpUrl, setHelpUrl] = useState("");

  // React.useEffect(() => {
  //   // setTimeout(() => {
  //   let text = `Namaste Utsav Admin🙏\u2029\u2029Help me register in: ${appBaseUrl}
  //         ${window?.location.pathname}`;

  //   setHelpUrl(
  //     `https://api.whatsapp.com/send?phone=91${CONTACT_NUMBER.number}&text=${text}`
  //   );
  //   // }, 5000);
  // }, []);

  return (
    <div id="event_footer" className={style.footerContainer}>
      <div className={style.footerDesktop}>
        <div className={style.footerSection}>
          <span className={style.RFmandal}>
            {" "}
            <LFmandal />
          </span>
          <span className={style.LFmandal}>
            <RFmandal />
          </span>

          <div className={style.leftDiv}>
            <div className={style.fUtsavTitle}>
              <span className={style.aboutSpan}>
                <Image
                  src={"/logo-spaced.webp"}
                  alt={t("utsav-logo")}
                  width="45"
                  height="45"
                  className={style.utsavLogo}
                />
              </span>
              <span className={style.brandname}>{t("utsav")}</span>
            </div>
            <div className={style.fInfo}>
              {t(
                "utsav-is-india-and-apos-s-most-loved-hindu-dharmik-app-offer-pujas-watch-darshan-and-receive-holy-prasad-at-your-home-directly-from-most-auspicious-temples-across-india"
              )}
            </div>
          </div>

          <div className={style.middleDiv}>
            <div className={style.fTitle}>{t("quick-links")}</div>

            <Stack className={style.ul}>
              <li className={style.fLList}>
                <Link
                  href={`/home/`}
                  target="_blank"
                  rel="noopener noreferrer alternate"
                  hrefLang={router.locale}
                >
                  <span className={style.linkSpan}> {t("visit-utsav")} </span>
                </Link>
              </li>

              <li className={style.fLList}>
                <Link
                  href={`/puja/`}
                  target="_blank"
                  rel="noopener noreferrer alternate"
                  hrefLang={router.locale}
                >
                  <span className={style.linkSpan}>{t("more-kriyas")}</span>
                </Link>
              </li>
              <li className={style.fLList}>
                <Link
                  href={`/blogs/`}
                  target="_blank"
                  rel="noopener noreferrer alternate"
                  hrefLang={router.locale}
                >
                  <span className={style.linkSpan}> {t("read-blogs")}</span>
                </Link>
              </li>
              {/* <li className={style.fLList}>
                <Link
                  href={`/post/`}
                  target="_blank"
                  rel="noopener noreferrer alternate"
                  hrefLang={router.locale}
                >
                  <span className={style.linkSpan}>{t("posts")}</span>
                </Link>
              </li> */}
            </Stack>
          </div>

          <div className={style.middleDiv}>
            <div className={style.fTitle}>{t("contact-us")}</div>
            <Stack className={style.ul}>
              <li className={style.fLList}>
                <Typography
                  sx={{
                    fontSize: "15px",
                  }}
                >
                  {"+91 7439888677"}
                </Typography>
                {/* <Link
                  href={`/about/`}
                  target="_blank"
                  rel="noopener noreferrer alternate"
                  hrefLang={router.locale}
                >
                  <span className={style.linkSpan}>{t("about-us")}</span>
                </Link> */}
              </li>
              <li className={style.fLList}>
                {/* <Link
                  href={`/vendor-terms-policy/`}
                  target="_blank"
                  rel="noopener noreferrer alternate"
                  hrefLang={router.locale}
                >
                  <span className={style.linkSpan}>{t("vendor-terms")}</span>
                </Link> */}
                <Link href={"mailto:support@utsavapp.in"}>
                  <Typography
                    className={style.termsLink}
                    sx={{ fontSize: "15px !important" }}
                  >
                    support@utsavapp.in
                  </Typography>
                </Link>
              </li>
              <li className={style.fLList}>
                {/* <Link
                  href={`/privacy-and-cookie-policy/`}
                  target="_blank"
                  rel="noopener noreferrer alternate"
                  hrefLang={router.locale}
                >
                  <span className={style.linkSpan}>{t("privacy-policy")}</span>
                </Link> */}
                <Link
                  href={`/terms-of-use?grievance=true`}
                  target="_blank"
                  rel="noopener noreferrer alternate"
                  hrefLang={router.locale}
                >
                  <Typography sx={{ fontSize: "15px" }}>
                    Grievance Redressal
                  </Typography>
                </Link>
              </li>
              {/* <li className={style.fLList}>
                <Link
                  href={`/terms-of-use/`}
                  target="_blank"
                  rel="noopener noreferrer alternate"
                  hrefLang={router.locale}
                >
                  <span className={style.linkSpan}>{t("terms-of-use")}</span>
                </Link>
              </li> */}
            </Stack>
          </div>

          <div className={style.middleDiv}>
            <div className={style.fTitle}>Company Office</div>
            <Stack className={style.ul}>
              {/* <div className={style.fLList}>
                <Typography>{"+91 7439888677"}</Typography>
              </div>
              <li className={style.fLList}>
                <span>{"support@utsavapp.in"}</span>
              </li> */}
              <div className={style.addressContainer}>
                <Typography className={style.addressText}>
                  {"Unit No. 705, Om Tower, 32,"}
                </Typography>
                <Typography className={style.addressText}>
                  {"Chowringhee Road,"}
                </Typography>
                <Typography className={style.addressText}>
                  Kolkata, West Bengal- 700071
                </Typography>
                <Typography className={style.addressText}>
                  CIN - U72900WB2021PTC249588
                </Typography>
              </div>
            </Stack>
          </div>

          <div className={style.sLContainer}>
            {/* <div className={style.sLContainerContact}>
              <div className={style.fTitle}>{t("contact-us")}</div>
              <span style={{ marginTop: "-24px" }}></span>
              <Link
                href={`tel:+91 ${CONTACT_NUMBER.number}`}
                target="_blank"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
                className={style.contactStyles}
              >
                +91 {CONTACT_NUMBER.number}
              </Link>

              <Link
                href="mailto:support@utsavapp.in"
                target="_blank"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
                className={style.emailStyles}
              >
                contact@utsavapp.in
              </Link>
            </div> */}
            <Link
              href={appDownloadUrl}
              target="_blank"
              rel="noopener noreferrer alternate"
            >
              <div className={style.googlePlayBanner}>
                <Image
                  src={GooglePlayWebp}
                  alt="Utsav App"
                  width={"150px"}
                  height={"50px"}
                  // layout="fill"
                  // objectFit="cover"
                />

                {/* <GooglePlayBtnIcon /> */}
              </div>
            </Link>
            <div className={style.SocialLink}>
              <Link
                href="https://www.linkedin.com/company/utsavapp/"
                target="_blank"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
                className={style.sLink}
              >
                <FaLinkedinIn className={style.sLIcon} />
              </Link>
              <Link
                href="https://www.facebook.com/utsav.app.community"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
                target="_blank"
                className={style.sLink}
              >
                <FaFacebookF className={style.sLIcon} />
              </Link>
              <Link
                href="https://www.instagram.com/utsav_app/"
                target="_blank"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
                className={style.sLink}
              >
                <FaInstagram className={style.sLIcon} />
              </Link>

              <Link
                href="https://twitter.com/utsav_app"
                target="_blank"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
                className={style.sLink}
              >
                <FaTwitter className={style.sLIcon} />
              </Link>
            </div>
            <Image src={DigitalIndia} alt="Utsav App" width="200" height="40" />
          </div>
        </div>

        <Divider sx={{ mb: 1 }} />
        <div className={style.copyright2}>
          {/* <Startup /> */}

          <Typography sx={{ ml: 1, fontSize: "12px" }}>
            © 2024 Nirvann Applications Private Limited. All rights reserved.
          </Typography>

          <Box className={style.terms}>
            <Link
              href={`/terms-of-use/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
            >
              <Typography className={style.termsLink}>Terms of Use</Typography>
            </Link>
            <Typography className={style.termsOr}> | </Typography>
            <Link
              href={`/privacy-and-cookie-policy/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
            >
              <Typography className={style.termsLink}>
                Privacy Policy
              </Typography>
            </Link>
            <Typography className={style.termsOr}> | </Typography>
            <Link
              href={`/vendor-terms-policy/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
            >
              <Typography className={style.termsLink}>Vendor Terms</Typography>
            </Link>
            <Typography className={style.termsOr}> | </Typography>
            <Link
              href={`/refund-policy/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
            >
              <Typography className={style.termsLink}>
                Refund & Cancellation Policy
              </Typography>
            </Link>
          </Box>
        </div>
      </div>

      <div className={style.footerSubContainer}>
        <Link
          href="https://whatsapp.com/channel/0029VaAPaQoJ3jv0AQa4jX36"
          target="_blank"
          rel="noopener noreferrer alternate"
          hrefLang={router.locale}
        >
          <div className={style.whatsappBanner}>
            <Image
              src="/assets/newEvent/pujaDetails/whatsappBanner.webp"
              alt="Utsav App"
              // width={"100%"}
              // height={"100%"}
              layout="fill"
              // objectFit="cover"
            />
          </div>
        </Link>
        {/* <Link
          href={appDownloadUrl}
          target="_blank"
          rel="noopener noreferrer alternate"
        > */}
        <div className={style.googlePlayBanner}>
          <Image
            src={GooglePlayWebp}
            alt="Utsav App"
            width={"150px"}
            height={"50px"}
            // layout="fill"
            // objectFit="cover"
          />
        </div>
        {/* </Link> */}
        {/* <span className={style.RFmandal}>
          {" "}
          <LFmandal />
        </span>
        <span className={style.LFmandal}>
          <RFmandal />
        </span> */}
        <div className={style.footerHeaderContainer}>
          <div className={style.footerHeader}>
            <div className={style.footerLogo}>
              <Image
                src={"/logo-spaced.webp"}
                alt={t("utsav-logo")}
                width="40"
                height="40"
                className={style.utsavLogo}
              />

              <Typography className={style.title}>{t("utsav")}</Typography>
            </div>

            <div className={style.socialMedia}>
              <Link
                href="https://www.linkedin.com/company/utsavapp/"
                target="_blank"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
              >
                <FaLinkedinIn className={style.socialMediaIcon} />
              </Link>
              <Link
                href="https://www.facebook.com/utsav.app.community"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
                target="_blank"
              >
                <FaFacebookF className={style.socialMediaIcon} />
              </Link>
              <Link
                href="https://www.instagram.com/utsav_app/"
                target="_blank"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
              >
                <FaInstagram className={style.socialMediaIcon} />
              </Link>

              <Link
                href="https://twitter.com/utsav_app"
                target="_blank"
                rel="noopener noreferrer alternate"
                hrefLang={router.locale}
              >
                <FaTwitter className={style.socialMediaIcon} />
              </Link>
            </div>
          </div>

          <div className={style.footerText}>
            <Typography>
              {t(
                "utsav-is-india-and-apos-s-most-loved-hindu-dharmik-app-offer-pujas-watch-darshan-and-receive-holy-prasad-at-your-home-directly-from-most-auspicious-temples-across-india"
              )}
            </Typography>
          </div>
        </div>
        <div className={style.linksSupport}>
          <div className={style.linksSupportHeader}>
            <Typography className={style.linkTitle}>
              {t("quick-links")}
            </Typography>
            <Typography className={style.linkTitle}>
              {t("contact-us")}
            </Typography>
          </div>
          <div className={style.linksSupportHeader}>
            <Link
              href={`/home/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
              className={style.fLink_1}
            >
              {t("visit-utsav")}
            </Link>
            {/* <Typography
              className={style.copyrightLinkOption}
              onClick={() => {
                window.open(helpUrl, "_blank");
              }}
            >
              {t("help")}
            </Typography> */}
            {/* <Link
              href={`/privacy-and-cookie-policy/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
            >
              <Typography className={style.copyrightLinkOption}>
                {t("privacy-policy")}
              </Typography>
            </Link> */}
            <Typography
              sx={{ fontSize: "14px", color: theme.palette.text.primaryDark }}
            >
              +91 7439888677
            </Typography>
          </div>

          <div className={style.linksSupportHeader}>
            <Link
              href={`/puja/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
              className={style.fLink_1}
            >
              {t("more-kriyas")}
            </Link>
            {/* <Link
              href={`/refund-policy/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
              className={style.fLink_1}
            >
              <Typography className={style.copyrightLinkOption}>
                {t("refund-policy")}
              </Typography>
            </Link> */}
            <Link href={"mailto:support@utsavapp.in"}>
              <Typography
                className={style.termsLink}
                sx={{
                  fontSize: "15px !important",
                  color: theme.palette.text.primaryDark,
                }}
              >
                support@utsavapp.in
              </Typography>
            </Link>
          </div>

          <div className={style.linksSupportHeader}>
            <Link
              href={`/blogs/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
              className={style.fLink_1}
            >
              {t("read-blogs")}
            </Link>
            {/* <Link
              href={`/terms-of-use/`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
            >
              <Typography className={style.copyrightLinkOption}>
                {t("terms-of-use")}
              </Typography>
            </Link> */}
            <Link
              href={`/terms-of-use?grievance=true`}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
            >
              <Typography
                sx={{ fontSize: "14px", color: theme.palette.text.primaryDark }}
              >
                Grievance Redressal
              </Typography>
            </Link>
          </div>
        </div>
      </div>

      <div className={style.contactContainer}>
        <div className={style.contactHeader}>
          <Typography className={style.contactTitle}>
            Company & Office
          </Typography>
        </div>
        <div className={style.contactInfo}>
          <Typography
            sx={{
              mb: "5px",
              fontSize: "14px",
              color: theme.palette.text.primaryDark,
            }}
          >
            Unit No. 705, Om Tower, 32, Chowringhee Road, Kolkata, West Bengal-
            700071
          </Typography>
          <Typography
            sx={{
              mb: "5px",
              fontSize: "14px",
              color: theme.palette.text.primaryDark,
            }}
          >
            CIN - U72900WB2021PTC249588
          </Typography>
        </div>
      </div>

      {/* <Divider sx={{ opacity: 0.25 }} /> */}

      {/* <div className={style.copyrightOptions}>
        <Image src={BottomDiya} alt="Utsav App" width="210" height="70" />
      </div> */}

      <div className={style.mobileCopyright}>
        <div>
          <Image src={DigitalIndia} alt="Utsav App" width="200" height="30" />
        </div>
        <Divider sx={{ width: "100%" }} />
        {/* <div className={style.copyrightLink}> </div> */}
        <Typography className={style.copyright}>
          © 2024 Nirvann Applications Pvt. Ltd. All rights reserved.
        </Typography>
        <div className={style.terms}>
          <Link
            href={`/terms-of-use/`}
            target="_blank"
            rel="noopener noreferrer alternate"
            hrefLang={router.locale}
          >
            <Typography className={style.termsLink}>Terms of Use</Typography>
          </Link>
          <Typography sx={{ fontSize: "12px" }}> | </Typography>
          <Link
            href={`/privacy-and-cookie-policy/`}
            target="_blank"
            rel="noopener noreferrer alternate"
            hrefLang={router.locale}
          >
            <Typography className={style.termsLink}>Privacy Policy</Typography>
          </Link>
          <Typography sx={{ fontSize: "12px" }}> | </Typography>
          <Link
            href={`/vendor-terms-policy/`}
            target="_blank"
            rel="noopener noreferrer alternate"
            hrefLang={router.locale}
          >
            <Typography className={style.termsLink}>Vendor Terms</Typography>
          </Link>
          <Typography sx={{ fontSize: "12px" }}> | </Typography>
          <Link
            href={`/refund-policy/`}
            target="_blank"
            rel="noopener noreferrer alternate"
            hrefLang={router.locale}
          >
            <Typography className={style.termsLink}>
              Refund & Cancellation Policy
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
